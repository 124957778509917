import React, { useState } from "react"
import { Link, withPrefix, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CookieConsent from "react-cookie-consent"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { REST } from "../components/apply/api-calls-map"

const isBrowser = typeof window !== "undefined"

const domain =
  isBrowser &&
  window &&
  window.location &&
  window.location.origin &&
  window.location.origin &&
  window.location.origin === "http://local.dev.trustic.com:8000"
    ? "http://dev.trustic.com"
    : isBrowser && window.location.origin

/*global dataLayer */
const Home = ({ data }) => {
  const breakpoints = useBreakpoint()

  const [stepLoading, setStepLoading] = useState(false)

  const initLoan = async () => {
    setStepLoading(true)
    const [res, status] = await REST({
      url: `${domain}/origination/api/public/init/loanApplication${
        localStorage.getItem("moneyReason")
          ? `?purpose=${localStorage.getItem("moneyReason")}`
          : ""
      }`,
      method: "POST",
    })

    if (status === 200) {
      document.body.click()
      document.getElementById("loan-purpose-message").style.display = "none"
      localStorage.setItem("businessId", res.businessId)
      dataLayer.push({
        borrower_app_business_id: res.businessId,
      })
      navigate("/apply")
    }
  }

  return (
    <>
      <Layout
        home
        dark={true}
        headerComponent={
          breakpoints.md ? (
            <header
              className="header h-fullscreen text-white"
              style={{
                backgroundImage: `url(${withPrefix(
                  "/pexels-keira-burton-6147171.jpeg"
                )})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="overlay opacity-60 bg-dark" />
              <div className="container">
                <div className="row align-items-center h-100">
                  <div className="col-md-10 m-auto text-center">
                    <h1 className="fw-600" style={{ zoom: "0.9" }}>
                      Harness the power of your community. Borrow affordably
                      with a guarantor.
                    </h1>
                    <br />
                    <p className="lead-3">
                      Personal loans from $1,100 to $3,000 as soon as the next
                      business day*,
                      <br />
                      APR from 9.95% to 35.99%.
                    </p>

                    <div className="dropdown mb-4 mt-5">
                      <button
                        id="moneyReason"
                        className="btn btn-xl btn-rounded btn-light mw-250 dropdown-toggle px-2"
                        data-toggle="dropdown"
                        style={{
                          maxWidth: "220px",
                          width: "100%",
                          fontSize: "8px",
                          zIndex: 2,
                        }}
                        aria-expanded="false"
                      >
                        What's the money for?
                      </button>
                      <p
                        id="loan-purpose-message"
                        className="mb-0"
                        style={{ display: "none" }}
                      >
                        <small className="text-danger">
                          Please select a loan purpose!
                        </small>
                      </p>
                      <div
                        className="dropdown-menu"
                        style={{
                          maxWidth: "286px",
                          width: "100%",
                          transformOrigin: "center center",
                          zIndex: 2,
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for unexpected expense"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for unexpected expense",
                            })
                          }}
                        >
                          Pay for unexpected expense
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover medical expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Cover medical expenses",
                            })
                          }}
                        >
                          Cover medical expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover educational expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Cover educational expenses",
                            })
                          }}
                        >
                          Cover educational expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for home improvement"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for home improvement",
                            })
                          }}
                        >
                          Pay for home improvement{" "}
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for car repair"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for car repair",
                            })
                          }}
                        >
                          Pay for car repair
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay off expensive debt"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay off expensive debt",
                            })
                          }}
                        >
                          Pay off expensive debt
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for a vacation"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for a vacation",
                            })
                          }}
                        >
                          Pay for a vacation
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Buy a new car")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Buy a new car",
                            })
                          }}
                        >
                          Buy a new car
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay to move or relocate"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay to move or relocate",
                            })
                          }}
                        >
                          Pay to move or relocate
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Other")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Other",
                            })
                          }}
                        >
                          Other
                        </a>
                      </div>
                    </div>
                    <a
                      className="btn btn-xl btn-rounded btn-danger mw-250"
                      href="/apply"
                      onClick={e => {
                        e.preventDefault()
                        dataLayer.push({ event: "check_your_rate_main" })
                        if (localStorage.getItem("moneyReason")) {
                          initLoan()
                        } else {
                          document.getElementById(
                            "loan-purpose-message"
                          ).style.display = "block"
                        }
                      }}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Check your rate"
                      )}
                    </a>
                    <p className="mt-3 mb-0 fs-13">
                      This won’t impact your credit score.
                    </p>
                    <p className="mt-3 mb-0 fs-16">
                      Fixed monthly repayments over 36 months.
                      <br />
                      No prepayment penalties.
                    </p>
                  </div>
                </div>
              </div>
            </header>
          ) : (
            <header className="header h-fullscreen text-white">
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 1,
                }}
                className="overlay"
                src={withPrefix("/1047837493-hd.mp4")}
                autoPlay
                muted
                loop
                preload="metadata"
                playsInline
              ></video>
              <div className="overlay opacity-60 bg-dark" />
              <div className="container">
                <div className="row align-items-center h-100">
                  <div className="col-md-10 m-auto text-center">
                    <h1 className="fw-600 display-3">
                      Harness the power of your community. Borrow affordably
                      with a guarantor.
                    </h1>
                    <br />
                    <p className="lead-4">
                      Personal loans from $1,100 to $3,000 as soon as the next
                      business day*,
                      <br />
                      APR from 9.95% to 35.99%.
                    </p>
                    <br />
                    <div className="dropdown" style={{}}>
                      <button
                        id="moneyReason"
                        className="btn btn-xl btn-rounded btn-light mw-250 dropdown-toggle px-2"
                        data-toggle="dropdown"
                        style={{
                          maxWidth: "220px",
                          width: "100%",
                          fontSize: "8px",
                          transform: "scale(1.3)",
                          transformOrigin: "center",
                          zIndex: 2,
                        }}
                      >
                        What's the money for?
                      </button>
                      <p
                        id="loan-purpose-message"
                        className="mb-0"
                        style={{ display: "none" }}
                      >
                        <small className="text-danger">
                          Please select a loan purpose!
                        </small>
                      </p>
                      <div
                        className="dropdown-menu"
                        style={{
                          maxWidth: "286px",
                          width: "100%",
                          transform: "scale(1.3)",
                          transformOrigin: "center",
                          zIndex: 2,
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for unexpected expense"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for unexpected expense",
                            })
                          }}
                        >
                          Pay for unexpected expense
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover medical expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Cover medical expenses",
                            })
                          }}
                        >
                          Cover medical expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Cover educational expenses"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Cover educational expenses",
                            })
                          }}
                        >
                          Cover educational expenses
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for home improvement"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for home improvement",
                            })
                          }}
                        >
                          Pay for home improvement{" "}
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for car repair"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for car repair",
                            })
                          }}
                        >
                          Pay for car repair
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay off expensive debt"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay off expensive debt",
                            })
                          }}
                        >
                          Pay off expensive debt
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay for a vacation"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay for a vacation",
                            })
                          }}
                        >
                          Pay for a vacation
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Buy a new car")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Buy a new car",
                            })
                          }}
                        >
                          Buy a new car
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem(
                              "moneyReason",
                              "Pay to move or relocate"
                            )
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Pay to move or relocate",
                            })
                          }}
                        >
                          Pay to move or relocate
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            document.body.click()
                            document.getElementById(
                              "loan-purpose-message"
                            ).style.display = "none"
                            localStorage.setItem("moneyReason", "Other")
                            document.getElementById(
                              "moneyReason"
                            ).innerText = localStorage.getItem("moneyReason")

                            dataLayer.push({
                              money_reason_main: "Other",
                            })
                          }}
                        >
                          Other
                        </a>
                      </div>
                    </div>
                    <br />
                    <a
                      href="/apply"
                      className="btn btn-xl btn-rounded btn-danger mw-250"
                      style={{
                        transform: "scale(1.3)",
                        transformOrigin: "center",
                      }}
                      onClick={e => {
                        e.preventDefault()
                        dataLayer.push({ event: "check_your_rate_main" })
                        if (localStorage.getItem("moneyReason")) {
                          initLoan()
                        } else {
                          document.getElementById(
                            "loan-purpose-message"
                          ).style.display = "block"
                        }
                      }}
                    >
                      {stepLoading ? (
                        <div
                          className="loader"
                          style={{
                            borderColor: "rgb(255 255 255 / 20%)",
                            borderLeftColor: "#fff",
                            fontSize: "2.4px",
                          }}
                        />
                      ) : (
                        "Check your rate"
                      )}
                    </a>
                    <p className="mt-3 mb-0 fs-16">
                      This won’t impact your credit score.
                    </p>
                    <p className="mt-3 mb-0 fs-18">
                      Fixed monthly repayments over 36 months. No prepayment
                      penalties.
                    </p>
                  </div>
                </div>
              </div>
            </header>
          )
        }
      >
        <section className="section text-white bg-dark py-4 text-center">
          <div className="container">
            <p className="lead">
              A representative example: You receive a loan of $1,500 repayable
              over 36 monthly installments with an interest rate of 15% and a 5%
              origination fee of $75, for an APR of 18.67%. In this example,
              $1,425 will be given to your guarantor as your agent and you will
              make 36 monthly payments of $52.
            </p>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">Why choose Trustic?</h3>
            <div className="row gap-y mt-4">
              <div className="w-100" />
              <div className="col-md-6 col-xl-4">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5 text-default">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-easy-64.png")}
                      alt="Straightforward"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="finding-a-guarantor" className="text-default">
                      Straightforward
                    </Link>
                  </h5>
                  <p className="text-default">
                    Simple application online. Once approved, funds disbursed
                    the same business day.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5 text-default">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-low-price-64.png")}
                      alt="Affordable Payments"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="finding-a-guarantor" className="text-default">
                      Affordable Payments
                    </Link>
                  </h5>
                  <p className="text-default">
                    We ensure that your monthly payments are comfortably
                    affordable.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5 text-default">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-trust-64.png")}
                      alt="Trusted Lender"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="finding-a-guarantor" className="text-default">
                      Trusted Lender
                    </Link>
                  </h5>
                  <p className="text-default">
                    Trustic is a direct lender. We treat our borrowers and
                    guarantors with respect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section text-white bg-dark py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              You don’t need to do it alone.
              <br />
              Leverage your trust network to borrow affordably.
            </h3>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <p className="lead-3">
                  Trustic allows borrowers to reduce the cost of their loans by
                  sharing credit risk with other people through guarantor
                  nomination. We understand that it takes time to build a credit
                  history that allows borrowing cheaply. We want to cut that
                  time by letting you use a guarantor and borrow cheaply right
                  now. To put it simply, if a friend or family member trusts you
                  to make loan payments, we can trust you too.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 bg-gray text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">
              What our customers are telling about us:
            </h3>
            <div className="row gap-y">
              <div className="col-md-6">
                <div className="card shadow-3">
                  <div className="card-body px-6">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p
                      className="text-quoted mb-5"
                      style={{ minHeight: "56px" }}
                    >
                      The lender that sees good in everything and everyone
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow-3">
                  <div className="card-body px-6">
                    <div className="rating mb-3">
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                      <label className="fa fa-star active" />
                    </div>
                    <p className="text-quoted mb-5">
                      As a guarantor, you are given a unique opportunity to
                      empower your friend or family member to stand on their
                      feet
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-6 display-5">How Trustic works</h3>
            <div className="row gap-y">
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        1
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">Tell us about yourself</h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        2
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">
                    Choose your loan offer, and repayment option your like best
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        3
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">
                    Let us know who your guarantor is
                  </h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div
                  className="card card-body shadow-3 text-center px-3"
                  style={{ minHeight: "255px" }}
                >
                  <p className="my-5 text-default">
                    <div className="step-icon" style={{ width: "auto" }}>
                      <span
                        className="iconbox bg-dark text-white"
                        style={{ borderRadius: "3px" }}
                      >
                        4
                      </span>
                    </div>
                  </p>
                  <h5 className="mt-2 mb-5 fw-500">Receive funds</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mx-auto">
                <Link
                  className="btn btn-xl btn-rounded btn-danger"
                  style={{
                    transform: "scale(1.3)",
                    transformOrigin: "center",
                  }}
                  to="/apply"
                  onClick={e => {
                    e.preventDefault()
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <p className="lead-3">
                  Our goal is to create the smoothest and fastest process
                  possible to receive your guarantor loan. If your guarantor
                  loan application has been successful, we pay the loan into
                  your guarantor's bank account on the same business day. It's
                  then your guarantor's responsibility to give the money to you.
                  Once the loan has been funded, we will keep both you and your
                  guarantor up-to-date with the account statements. You are also
                  able to see the balance online 24/7 by logging into your
                  account. Don't worry, we will send your guarantor their
                  personal login link.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-7 text-center">
          <div className="container">
            <h3 className="fw-600 mb-3 display-5">Have questions?</h3>
            <p className="mb-6 lead-2">We’ve got the answers</p>
            <div className="row gap-y">
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-user-group-2-64.png")}
                      alt="Finding a Guarantor"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Finding a Guarantor</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-guarantee-64.png")}
                      alt="Becoming a Guarantor"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Becoming a Guarantor</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-apply-50.png")}
                      alt="Applying for a Loan"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Applying for a Loan</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card card-body shadow-3 text-center px-6">
                  <p className="my-5">
                    <img
                      style={{
                        width: "auto",
                        height: "60px",
                      }}
                      src={withPrefix("/icons8-product-documents-64.png")}
                      alt="Rates & Terms"
                    />
                  </p>
                  <h5 className="mb-5 fw-500">
                    <Link to="/hub">Rates & Terms</Link>
                  </h5>
                  <Link to="/hub" className="btn btn-outline-dark">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-2 pb-7 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mx-auto">
                <Link
                  className="btn btn-xl btn-rounded btn-danger"
                  style={{
                    transform: "scale(1.3)",
                    transformOrigin: "center",
                  }}
                  to="/hub"
                >
                  Visit Q&A Hub
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <Seo title="Home" />
      Î */}
        <CookieConsent
          buttonText="Accept"
          disableButtonStyles
          buttonClasses="btn btn-md btn-light fw-800 mr-3"
          containerClasses="text-center text-sm-left justify-content-center align-items-center pb-4 pb-sm-0"
        >
          We use cookies on this site to enhance your user experience. By using
          our site, you acknowledge that you have read and understand our
          policies.{" "}
          <Link
            className="text-white"
            style={{ textDecoration: "underline" }}
            activeClassName="active"
            to="/privacy"
          >
            Privacy Policy
          </Link>
        </CookieConsent>
      </Layout>
      <Seo
        title="Trustic | Guarantor personal loans"
        description="Don’t let your credit history to hold you back. Borrow affordably with a guarantor."
      />
    </>
  )
}

export default Home
